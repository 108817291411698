import useFetch from './useFetch'
import { IPage, IApplicationState } from '@bees-web/nfa-types'
import { QueryParams } from '../interfaces'
import { jokerUrlBuilder } from '../tools/joker'

export const pageUrlBuilder = (
  pageName: string,
  query: QueryParams = {},
  state: IApplicationState = null
): string => jokerUrlBuilder('pages', { ...query, pageName }, state)

export default function usePageSchema(
  pageKey: string,
  params: QueryParams,
  state: IApplicationState = null,
  serverSideData: IPage = null
): IPage {
  const pageUrl = pageUrlBuilder(pageKey, params, state)
  const { data, error } = useFetch(pageUrl, serverSideData)

  if (error) {
    console.error(error)
  }

  return data
}
