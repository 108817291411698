/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
import axios from 'axios'
import Agent from 'agentkeepalive'
import Router from 'next/router'
import { ServerContext } from '../core/interfaces'
import { BareFetcher } from 'swr'

const httpAgent = new Agent({
  keepAlive: true,
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
})

const httpsAgent = new Agent.HttpsAgent({
  keepAlive: true,
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
})

const jokerClient = axios.create({
  httpAgent,
  httpsAgent,
})

const handleUnauthorizedRequest = (isServer, context, unloggedPage) => {
  console.log('Request do not authorized: 401 status')
  if (isServer) {
    context.res.writeHead(302, { Location: unloggedPage })
    context.res.end()
  } else {
    Router.push(unloggedPage)
  }
}

const processStoreQueryParams = (context) => {
  let queryParams = ''
  const query = context?.req?.query || {}
  if (query['store']) {
    queryParams += `&store=${query['store']}`
  }
  if (query['storeId']) {
    queryParams += `&storeId=${query['storeId']}`
  }
  return queryParams
}

const fetcher =
  <T>(context?: ServerContext): BareFetcher<T> =>
  async (url: string): Promise<T> => {
    const isServer = !!context?.req
    const baseUrl = isServer
      ? process.env.JOKER_URL
      : window?.location?.origin || ''

    const fullUrl = new URL(
      `${url}${processStoreQueryParams(context)}`,
      baseUrl
    )

    try {
      const response = await jokerClient.get(fullUrl.toString(), {
        ...(isServer
          ? {
              headers: { cookie: context.req.headers.cookie || '' },
            }
          : {}),
        withCredentials: true,
      })

      if (response.headers['set-cookie']) {
        context.res.setHeader('Set-Cookie', response.headers['set-cookie'])
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 409)
      ) {
        const unloggedPage = process.env.LOGIN_URL_REDIRECT || '/login'
        handleUnauthorizedRequest(isServer, context, unloggedPage)
      } else {
        console.error('Error fetching data:', error.message)
      }
      return error
    }
  }

export default fetcher
