/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import { CustomRender } from '../render-connector'
import {
  DynamicPageProps,
  PageIdentity,
  QueryParams,
  SWRCache,
} from '../interfaces'
import {
  IPage,
  IInteractiveState,
  IApplicationState,
  DynamicVSConfiguration,
} from '@bees-web/nfa-types'
import fetcher from '../fetcher'
import { pageUrlBuilder } from '../hooks/usePageSchema'
import { useValueStream } from '../../utils/VL'
import PageComponent from '../components/Page'
import { useDispatch, useSelector } from 'react-redux'
import { interactiveGlobalMap } from '../../interactive/interactive-global-map'
import { getBlocksServerData } from '../tools/joker'
import { isPotentiallyLowEndDevice } from '../tools/infos'
import { SWRConfig } from 'swr/_internal'
import { getGlobalActions } from '../../interactive'
import loadReduxContext from '../../utils/VL/context/loadReduxContext'
import { dependencies } from '../../config'

const Page = (props: DynamicPageProps) => {
  const { namespace, globalNamespace, fallback } = props
  const {
    interactiveMap: processInteractive,
    isLoading,
    stateSelector,
  } = useValueStream(namespace)

  const dispatch = useDispatch()

  const state = useSelector((applicationState: IApplicationState) => {
    if (stateSelector) {
      return {
        ...applicationState,
        ...stateSelector(applicationState),
      }
    }

    return applicationState
  })

  const interactiveMap = isLoading
    ? {}
    : {
        ...processInteractive(dispatch, state, getGlobalActions()),
        ...interactiveGlobalMap({
          dispatch,
          state,
          globalNamespace: globalNamespace
            ? (globalNamespace as string)
            : 'default',
        }),
      }

  const pageProps = {
    ...props,
    interactiveMap,
    state,
  }

  return !isLoading ? (
    <SWRConfig value={{ fallback }}>
      <PageComponent {...pageProps} />
    </SWRConfig>
  ) : null
}

const ExperimentalSSRRender: CustomRender = {
  Page,
  ssr: async (context) => {
    const {
      req,
      store,
      namespace,
      locale,
      selectedPocAccount,
      pageName,
      pageConfig: { options = {}, dependencies: pageDependencies },
    } = context

    // TODO: remove state from serverSide
    const commonDependencies = (
      dependencies[namespace] as DynamicVSConfiguration
    ).dependencies

    const modules = await loadReduxContext(store, [
      ...(pageDependencies || commonDependencies),
      namespace,
    ])

    console.info(`SSR: Calling getServerSideProps():`, {
      pageName,
      locale,
      selectedPocAccount,
      modules,
    })

    // TODO: Analysis this code-snippet to make sure that needs entire state here
    const entireState = store.getState()
    const state: IInteractiveState<IApplicationState> = {
      globals: entireState.globals,
      state: entireState[namespace],
    }

    const pageIdentity: PageIdentity = {
      locale,
      selectedPocAccount,
    }

    const { id } = context.params as QueryParams
    if (id) {
      pageIdentity.entityId = id
    }

    const queryToRequestServer = {
      ...context.query,
      ...pageIdentity,
    }

    const pageUrl = pageUrlBuilder(pageName, queryToRequestServer, state)

    const page = await fetcher<IPage>(context)(pageUrl)

    const { disableDeferredSSR = false } = options
    const shouldProcessBlocks =
      typeof disableDeferredSSR === 'string' &&
      disableDeferredSSR.toLowerCase() === 'auto'
        ? isPotentiallyLowEndDevice(req.headers['user-agent'])
        : !disableDeferredSSR

    const cachedBlocks = shouldProcessBlocks
      ? await getBlocksServerData(page, context, queryToRequestServer, state)
      : null

    const cache: SWRCache = {
      ...cachedBlocks,
      [pageUrl]: page,
    }

    return {
      props: {
        fallback: cache,
        featureFlags: page?.featureFlags || {},
      },
    }
  },
}

export default ExperimentalSSRRender
