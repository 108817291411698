/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect } from 'react'
import usePageSchema from '../hooks/usePageSchema'
import MetaPage from './MetaPage'
import Block from './Block'
import { DynamicPageProps, PageIdentity } from '../interfaces'
import { IApplicationState } from '@bees-web/nfa-types'
import { IInteractiveMap } from '../../blocks/interfaces'
import { useDispatch } from 'react-redux'
import { pageTracking } from '@bees-web/nfa-interactive-global/actions/page'
import { getPageTracking } from '../segment'

export interface PageProps extends DynamicPageProps {
  interactiveMap: IInteractiveMap
  state: IApplicationState
}

const Page: FC<PageProps> = ({
  name,
  interactiveMap,
  state,
  query,
  params,
  selectedPocAccount,
  locale,
  segmentPageName,
}: PageProps) => {
  const dispatch = useDispatch()
  const pageIdentity: PageIdentity = { locale, selectedPocAccount }
  const {
    authentication: { initialized },
  } = state.globals

  const { id } = params
  if (id) {
    pageIdentity.entityId = id
  }

  const queryToRequestServer = {
    ...query,
    ...pageIdentity,
  }

  const template = usePageSchema(name, queryToRequestServer, state)

  useEffect(() => {
    if (initialized) {
      dispatch(pageTracking(getPageTracking(segmentPageName)))
    }
  }, [initialized, segmentPageName, dispatch])

  return template && interactiveMap ? (
    <MetaPage template={template}>
      {template.blocks.map((block) => (
        <Block
          key={block.name}
          block={block}
          interactiveMap={interactiveMap}
          queryToRequest={queryToRequestServer}
          state={state}
        />
      ))}
    </MetaPage>
  ) : null
}

export default Page
