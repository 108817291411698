/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { dependencies } from '../../config'
import { CustomRender } from '../render-connector'
import { DynamicPageProps } from '../interfaces'

const Page = (props: DynamicPageProps) => {
  const RootComponent = dependencies[props.namespace].components[props.name]
  const state = useSelector((state) => state[props.namespace])
  const store = useStore()
  const attributes = { ...props, state, key: null, store }
  return RootComponent
    ? React.createElement(RootComponent as any, attributes, null)
    : null
}

const PureReactRender: CustomRender = {
  Page,
}

export default PureReactRender
