/* eslint-disable security/detect-object-injection */
import useFetch from './useFetch'
import { IBlock, IApplicationState } from '@bees-web/nfa-types'
import { BlocksServerData, QueryParams } from '../interfaces'
import { urlToObjectParams } from '../tools/url'
import { jokerUrlBuilder } from '../tools/joker'

export const blockUrlBuilder = (
  url: string,
  params: QueryParams,
  state: IApplicationState = null
): string =>
  jokerUrlBuilder('blocks', { ...urlToObjectParams(url), ...params }, state)

export default function useBlockSchema(
  url: string,
  params: QueryParams,
  state: IApplicationState = null,
  serverSideData: BlocksServerData = null
): IBlock {
  const blockUrl = blockUrlBuilder(url, params, state)
  const fallbackData = serverSideData ? serverSideData[blockUrl] : null

  const { data, error } = useFetch(blockUrl, fallbackData)

  if (error) {
    console.error(error)
  }

  return data
}
