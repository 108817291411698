/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import Block, { BlockProps } from './Block'
import useBlockSchema from '../hooks/useBlockSchema'
import { getDeferredUrl } from '../tools/joker'
import useReadyToFetch from '../hooks/useReadyToFetch'

const LoadingBlock = ({ block, ...props }: BlockProps) => {
  const defaultBlock = {
    ...block,
    deferred: null,
  }
  return <Block block={defaultBlock} {...props} />
}

const FetchBlock = ({ block, ...props }: BlockProps) => {
  const { state, queryToRequest } = props

  const loadedBlockSchema = useBlockSchema(
    getDeferredUrl(block).url,
    queryToRequest,
    state
  )

  if (loadedBlockSchema) {
    const loadedBlock = {
      ...block,
      blocks: loadedBlockSchema.blocks,
      deferred: null,
    }

    return <Block block={loadedBlock} {...props} />
  }

  return <LoadingBlock block={block} {...props} />
}

const DeferredBlock = (props: BlockProps) => {
  const {
    block: { waitForState = [] },
    state,
  } = props

  const readyToFetch = useReadyToFetch(waitForState, state)

  return readyToFetch ? <FetchBlock {...props} /> : <LoadingBlock {...props} />
}

export default DeferredBlock
