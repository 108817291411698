/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable brace-style */
import { GetServerSidePropsContext } from 'next'
import { FunctionComponent } from 'react'
import { Store } from 'redux'
import { DynamicPageProps, QueryParams } from './interfaces'

export const NFA_DEFAULT_KEY = 'nfa'

export type SSRFunction = (
  context: GetServerSidePropsContext & {
    store: Store
    locale: string
    selectedPocAccount: string
    namespace: string
    isLocaleSupported: boolean
    pathname: string
    pageName: string
    query: QueryParams
    params: QueryParams
    pageConfig: {
      dependencies?: string[]
      options?: Record<string, unknown>
    }
  }
) => Promise<
  | {
      [key: string]: unknown
    }
  | null
  | undefined
  | void
>

export type CustomRender = {
  Page: FunctionComponent<DynamicPageProps>
  ssr?: SSRFunction
}

export default class RenderConnector {
  source: string
  name: string
  params: { [key: string]: unknown }
  constructor(source: string) {
    const parts = source.split(':')
    if (parts.length === 1) {
      this.source = NFA_DEFAULT_KEY // this is the default connector
      this.name = parts[0].trim()
    } else {
      this.source = parts[0].toLowerCase().trim()
      this.name = parts.slice(1).join(':').trim()
    }
  }
}
