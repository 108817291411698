/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import Iframe from 'react-iframe'
import { CustomRender } from '../render-connector'
import { DynamicPageProps } from '../interfaces'

const Page = (props: DynamicPageProps) => {
  return (
    <Iframe
      url={props.name}
      className=""
      display="block"
      position="relative"
      styles={{
        height: '100vh' /* Viewport-relative units */,
        width: '100vw',
      }}
    />
  )
}

const WebRender: CustomRender = {
  Page,
}

export default WebRender
