import useSWR, { SWRResponse } from 'swr'
import fetcher from '../fetcher'

const options = {
  revalidateIfStale: false,
  errorRetryCount: 5,
  errorRetryInterval: 10000,
  dedupingInterval: 60000,
  revalidateOnFocus: false,
}

const useFetch = (url: string, fallbackData: unknown = null): SWRResponse =>
  useSWR(
    url,
    fetcher(),
    fallbackData
      ? {
          ...options,
          fallbackData,
        }
      : options
  )

export default useFetch
