/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import React, { FC, createElement } from 'react'
import { useStore } from 'react-redux'
import { IBlock, IApplicationState } from '@bees-web/nfa-types'
import DeferredBlock from './DeferredBlock'
import { QueryParams } from '../interfaces'
import { getComponent } from '../../utils/VL'
import { IInteractiveMap } from '../../blocks/interfaces'
import { mergeProperties as deepMerge } from '../../utils/mergeProperties'
import { CUSTOM_BLOCK_TAG } from '../../utils/VL/getComponent'
import getSiteMap from '../routing/getSiteMap'

export interface BlockProps {
  block: IBlock
  interactiveMap: IInteractiveMap
  queryToRequest: QueryParams
  state: IApplicationState
}

const sitemap = getSiteMap()

const Block: FC<BlockProps> = ({ block, ...props }: BlockProps) => {
  const store = useStore()

  const Component = getComponent(block) as any

  if (!Component) {
    return null
  }

  const { deferred } = block

  if (deferred && global.window) {
    return <DeferredBlock block={block} {...props} />
  }

  block.meta = block.meta || {}

  setBlockMeta(block)

  const interactivity = block.interactiveId
    ? props.interactiveMap[block.interactiveId]
    : null

  const { attributes } = interactivity || {}
  const { blocks = [], mergeProperties = false, ...blockProps } = block
  const { mergeProperties: mergeProps = mergeProperties } = attributes || {}

  const newProps = mergeProps
    ? deepMerge(blockProps.attributes, attributes)
    : Object.assign(blockProps.attributes || {}, attributes)

  const componentProps = {
    ...blockProps,
    ...interactivity,
    ...blockProps.attributes,
    attributes: newProps,
    ...(block.blockType === CUSTOM_BLOCK_TAG ? { store } : {}),
  }

  return createElement(
    Component,
    componentProps,
    blocks.map((block) => <Block key={block.name} block={block} {...props} />)
  )
}

function setBlockMeta(block: IBlock) {
  if (block.attributes?.href) {
    const { href } = block.attributes
    const dynamicVariation = `${href.substring(0, href.lastIndexOf('/'))}/[id]`

    block.meta.externalLink =
      href === '/' ||
      (!sitemap.routes[href] && !sitemap.routes[dynamicVariation])

    if (sitemap?.dynamicRoutes[dynamicVariation]) {
      block.meta.dynamicRoute = dynamicVariation
    }
  }
}

export default Block
