/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import getApplicationRoutes from '../core/routing'
import wrapper from '../redux'
import { GetServerSidePropsResult } from 'next'
import renders from '../core/renders'
import RenderConnector from '../core/render-connector'
import cookies from 'next-cookies'
import uuidv4 from '@bees-web/nfa-interactive-global/utils/uuidGenerator'
import { getDomainLocale, getPathInfo } from '../utils/VL'
import { DynamicPageProps, QueryParams } from '../core/interfaces'
import { getDeviceType } from '../core/tools/infos'
import { checkAASA } from '../core/tools/url'
import { logger } from '../utils/logger'
import { updateUser } from '@bees-web/nfa-interactive-global/actions/user/updateUser'

type BuildResponseParams = {
  namespace?: string
  connector?: string
  pageName?: string
  segmentPageName?: string
  config?: any
  query?: QueryParams
  params?: QueryParams
  customSSRResponse?: {
    props?: Record<string, unknown>
    redirect?: Record<string, unknown>
    notFound?: boolean
  }
  [key: string]: any
}

const DynamicPage = (props: DynamicPageProps) => {
  const Render = renders[props.connector].Page
  return <Render {...props} />
}

export const getServerSideProps = wrapper.getServerSideProps<DynamicPageProps>(
  (store) =>
    async (context): Promise<GetServerSidePropsResult<DynamicPageProps>> => {
      const { pathkey, path, pathname } = getPathInfo(context.resolvedUrl)
      const device = getDeviceType(context.req.headers['user-agent'])

      const { ajs_user_id = null, ajs_anonymous_id = null } = cookies(context)
      const safeAjsUserId = ajs_user_id?.replace(/[^a-z0-9-_]/gi, '') || null
      const { globals } = store.getState()
      const { selectedPocAccount } = globals.cache

      const { isSupported, locale, country, language } =
        getDomainLocale(context)
      const ajsAnonymousId = ajs_anonymous_id || uuidv4()

      const hasAASA = checkAASA({ res: context.res, country, path })
      if (hasAASA) {
        return {
          props: {
            connector: 'nfa',
            namespace: 'core',
            ajsAnonymousId,
            ajsUserId: safeAjsUserId,
            isServer: true,
            isLocaleSupported: true,
            name: 'aasa',
            pathname,
            language,
            country,
            query: {},
            params: {},
            locale,
            scripts: [],
            options: {},
          },
        }
      }

      const buildResponse = ({
        namespace = null,
        connector = null,
        pageName = null,
        segmentPageName = null,
        config = {},
        query = {},
        params = {},
        customSSRResponse = {},
      }: BuildResponseParams = {}): GetServerSidePropsResult<DynamicPageProps> => {
        if (!connector || !pageName) {
          return {
            redirect: {
              permanent: false,
              destination: '/not_found',
            },
          }
        }

        return {
          ...customSSRResponse,
          props: {
            isServer: true,
            isLocaleSupported: isSupported,
            name: pageName,
            segmentPageName,
            namespace,
            connector,
            language,
            country,
            query,
            params,
            scripts: config?.scripts ? config.scripts : [],
            pathname,
            ajsUserId: safeAjsUserId,
            ajsAnonymousId,
            selectedPocAccount: selectedPocAccount ?? null,
            locale,
            options: config?.options || {},
            ...customSSRResponse?.props,
          },
        }
      }

      if (isSupported) {
        store.dispatch(
          updateUser({
            country,
            language,
          })
        )

        logger('info', {
          page: `pathname: ${pathname}`,
          locale: `locale: ${locale}`,
          message: `Slug serverSideProps => country: ${country}, language: ${language}, locale: ${locale}`,
        })

        const routes = getApplicationRoutes()
        let routeConfig = routes.statics[path]
        let params = {}

        if (!routeConfig) {
          const dynamics =
            routes.dynamics[pathkey] || routes.dynamics._root || {}
          Object.keys(dynamics).some((key) => {
            const route = dynamics[key]
            const pathdata = route.process(path)

            if (pathdata) {
              routeConfig = route
              params = pathdata.params || {}
            }
            return !!pathdata
          })
        }
        if (routeConfig) {
          const {
            source,
            namespace,
            segmentPageName = null,
            ...config
          } = routeConfig
          const renderConnector = new RenderConnector(source)
          const { name: pageName, source: connector } = renderConnector
          const { ssr } = renders[connector]
          const { slug, ...contextQuery } = context.query

          const query: QueryParams = { device }
          Object.entries(contextQuery).forEach(([key, value]) => {
            query[key] = Array.isArray(value) ? value.join(',') : value
          })

          // Remove Fastly query insertion
          delete query['language']
          delete query['country']

          console.info('Using Dynamic Routing System:', {
            connector,
            source,
            namespace,
            params,
            slug,
            config,
          })

          const response = ssr
            ? await ssr({
                ...context,
                namespace,
                store,
                locale,
                isLocaleSupported: true,
                selectedPocAccount,
                pathname,
                pageName,
                pageConfig: config,
                query,
                params,
              })
            : {}

          return buildResponse({
            namespace,
            connector,
            pageName,
            segmentPageName,
            config,
            query,
            params,
            customSSRResponse: response || null,
          })
        }
      } else {
        console.error('Domain not supported!')
      }

      return buildResponse()
    }
)

export default DynamicPage
