import { CustomRender, NFA_DEFAULT_KEY } from '../render-connector'
import nfa from './Nfa'
import react from './PureReact'
import cdn from './Cdn'
import web from './Web'
import experimentalssr from './ExperimentalSSR'

export interface Renders {
  [key: string]: CustomRender
}

const renders: Renders = {
  [NFA_DEFAULT_KEY]: nfa,
  react,
  cdn,
  web,
  experimentalssr,
}

export default renders
