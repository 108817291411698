import React from 'react'
import { CustomRender } from '../render-connector'
import { DynamicPageProps } from '../interfaces'

const Page = (props: DynamicPageProps) => {
  return <div>{props.name}</div>
}

const CdnRender: CustomRender = {
  Page,
}

export default CdnRender
