/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { dependencies } from '../../config'
import Router from 'next/router'
import { CustomRender } from '../render-connector'
import { GlobalNamespaces } from '../../interfaces'
import { loadPage } from '@bees-web/nfa-interactive-global/actions/cache'
import SimpleVSPage from '../../components/simpleVSPage'
import { DynamicPageProps } from '../interfaces'
import getSiteMap from '../routing/getSiteMap'

const sitemap = getSiteMap()

const Page = ({ namespace, name, scripts, options }: DynamicPageProps) => {
  const { globalNamespace = GlobalNamespaces.DEFAULT } = dependencies[namespace]

  return SimpleVSPage({
    namespace,
    globalNamespace,
    scripts: scripts.map((src) => ({
      src,
      strategy: 'beforeInteractive',
    })),
    options,
  })({ name })
}

const TemplateRender: CustomRender = {
  Page,
  ssr: async (context) => {
    const {
      store,
      locale,
      selectedPocAccount,
      resolvedUrl: asPath,
      pathname,
      pageName,
      query,
      params,
    } = context

    const requestJoker = async (
      pageName: string,
      params: { [key: string]: unknown }
    ): Promise<void> => {
      const { id } = params
      await store.dispatch(
        loadPage({
          query: {
            ...params,
            pageName,
            locale,
            accountId: selectedPocAccount,
            entityId: id ? (id as string) : null,
          },
          context,
          Router,
          sitemap,
          pageRoute: pathname,
        }) as any
      )
    }

    await requestJoker(pageName, { ...query, ...params })

    return {
      props: {
        featureFlags:
          store.getState().globals?.cache.pages[pageName]?.featureFlags || {},
      },
    }
  },
}

export default TemplateRender
