import { useState, useEffect } from 'react'
import { normalizeStateExpression } from '../tools/joker'

export default function useReadyToFetch(waitForState, state) {
  const [readyToFetch, setReadyToFetch] = useState(waitForState.length === 0)

  useEffect(() => {
    const isReady = waitForState.every((attribute) => {
      const attrParts = normalizeStateExpression(attribute).split('.')
      let value = state

      for (const part of attrParts) {
        value = value?.[part]

        if (value === undefined || value === null) {
          return false
        }
      }

      return true
    })

    if (isReady !== readyToFetch) setReadyToFetch(isReady)
  }, [state, waitForState])

  return readyToFetch
}
